'use strict';

var DragMixin = {
    _initDraggableLayer: function _initDraggableLayer() {
        var _this = this;

        // temporary coord variable for delta calculation
        this._tempDragCoord = null;

        // add CSS class
        var el = this._layer._path;
        L.DomUtil.addClass(el, 'leaflet-pm-draggable');

        var onMouseUp = function onMouseUp() {
            // re-enable map drag
            _this._layer._map.dragging.enable();

            // clear up mousemove event
            _this._layer._map.off('mousemove');

            // clear up mouseup event
            _this._layer.off('mouseup');

            // if no drag happened, don't do anything
            if (!_this._dragging) {
                return false;
            }

            // show markers again
            _this._initMarkers();

            // timeout to prevent click event after drag :-/
            // TODO: do it better as soon as leaflet has a way to do it better :-)
            window.setTimeout(function () {
                // set state
                _this._dragging = false;
                L.DomUtil.removeClass(el, 'leaflet-pm-dragging');

                // fire pm:dragend event
                _this._layer.fire('pm:dragend');

                // fire edit
                _this._fireEdit();
            }, 10);

            return true;
        };

        var onMouseMove = function onMouseMove(e) {
            if (!_this._dragging) {
                // set state
                _this._dragging = true;
                L.DomUtil.addClass(el, 'leaflet-pm-dragging');

                // bring it to front to prevent drag interception
                _this._layer.bringToFront();

                // disbale map drag
                _this._layer._map.dragging.disable();

                // hide markers
                _this._markerGroup.clearLayers();

                // fire pm:dragstart event
                _this._layer.fire('pm:dragstart');
            }

            _this._onLayerDrag(e);
        };

        this._layer.on('mousedown', function (e) {
            // save for delta calculation
            _this._tempDragCoord = e.latlng;

            _this._layer.on('mouseup', onMouseUp);

            // listen to mousemove on map (instead of polygon),
            // otherwise fast mouse movements stop the drag
            _this._layer._map.on('mousemove', onMouseMove);
        });
    },
    dragging: function dragging() {
        return this._dragging;
    },
    _onLayerDrag: function _onLayerDrag(e) {
        // latLng of mouse event
        var latlng = e.latlng;

        // delta coords (how far was dragged)
        var deltaLatLng = {
            lat: latlng.lat - this._tempDragCoord.lat,
            lng: latlng.lng - this._tempDragCoord.lng
        };

        // create the new coordinates array
        var coords = void 0;

        if (this._layer instanceof L.Polygon) {
            coords = this._layer._latlngs[0];
        } else {
            coords = this._layer._latlngs;
        }

        var newLatLngs = coords.map(function (currentLatLng) {
            return {
                lat: currentLatLng.lat + deltaLatLng.lat,
                lng: currentLatLng.lng + deltaLatLng.lng
            };
        });

        // set new coordinates and redraw
        this._layer.setLatLngs(newLatLngs).redraw();

        // save current latlng for next delta calculation
        this._tempDragCoord = latlng;

        // fire pm:dragstart event
        this._layer.fire('pm:drag');
    }
};
'use strict';

// this isn't included anymore but if you want to re-enable it:
// 1. include this mixing inside L.PM.Edit.js
// 2. include the turf.js dependency in your project before leaflet.pm
// 3. uncomment all code inside L.PM.Edit.Poly that has an if-check on options.preventOverlap
// 4. pass the option preventOverlap to the enable() function on your layer
var OverlapMixin = {
    _applyPossibleCoordsChanges: function _applyPossibleCoordsChanges() {
        // after the polygon was dragged and changed it's shape because of unallowed intersecting
        // with another polygon, this function takes the temporarily drawn polygon (during drag) and applies
        // it's coordinates to our main polygon

        if (this._tempPolygon) {
            // get the new coordinates
            var latlngs = this._tempPolygon.getLayers()[0].getLatLngs();

            // reshape our main polygon
            this._poly.setLatLngs(latlngs).redraw();

            // initialize the markers again
            this._initMarkers();
        }
    },
    _drawTemporaryPolygon: function _drawTemporaryPolygon(geoJson) {
        // hide our polygon
        this._poly.setStyle({ opacity: 0, fillOpacity: 0 });

        // draw a temporary polygon (happens during drag & intersection)
        this._tempPolygon = L.geoJson(geoJson).addTo(this._poly._map).bringToBack();
    },
    _handleOverlap: function _handleOverlap() {
        var mainPoly = this._poly;
        var layers = this._layerGroup.getLayers();
        var changed = false;
        var resultingGeoJson = this._poly.toGeoJSON();

        layers.filter(function (layer) {
            return !Object.is(layer, mainPoly);
        }).map(function (layer) {
            var intersect = void 0;

            // this needs to be in a try catch block because turf isn't reliable
            // it throws self-intersection errors even if there are none
            try {
                intersect = turf.intersect(resultingGeoJson, layer.toGeoJSON());
            } catch (e) {
                console.warn('Turf Error.');
            }

            if (intersect) {
                resultingGeoJson = turf.difference(resultingGeoJson, layer.toGeoJSON());

                // if the resulting polygon is a MultiPolygon, don't handle it.
                if (resultingGeoJson.geometry.type !== 'MultiPolygon') {
                    changed = true;
                }
            }

            return true;
        });

        if (this._tempPolygon) {
            this._tempPolygon.remove();
            delete this._tempPolygon;
        }

        if (changed) {
            this._drawTemporaryPolygon(resultingGeoJson);
        } else {
            this._poly.setStyle({ opacity: 1, fillOpacity: 0.2 });
        }
    }
};
'use strict';

var SnapMixin = {
    _initSnappableMarkers: function _initSnappableMarkers() {
        var _this = this;

        this.options.snapDistance = this.options.snapDistance || 30;

        this._markers.forEach(function (marker) {
            marker.off('drag', _this._handleSnapping, _this);
            marker.on('drag', _this._handleSnapping, _this);

            marker.off('dragend', _this._cleanupSnapping, _this);
            marker.on('dragend', _this._cleanupSnapping, _this);
        });

        this._layer.off('pm:dragstart', this._unsnap, this);
        this._layer.on('pm:dragstart', this._unsnap, this);
    },
    _unsnap: function _unsnap() {
        // delete the last snap
        delete this._snapLatLng;
    },
    _cleanupSnapping: function _cleanupSnapping() {
        // delete it, we need to refresh this with each start of a drag because
        // meanwhile, new layers could've been added to the map
        delete this._snapList;

        this.debugIndicatorLines.forEach(function (line) {
            line.remove();
        });
    },
    _handleSnapping: function _handleSnapping(e) {
        // create a list of polygons that the marker could snap to
        // this isn't inside a movestart/dragstart callback because middlemarkers are initialized
        // after dragstart/movestart so it wouldn't fire for them
        if (this._snapList === undefined) {
            this._createSnapList(e);
        }

        // if there are no layers to snap to, stop here
        if (this._snapList.length <= 0) {
            return false;
        }

        var marker = e.target;

        // get the closest layer, it's closest latlng, segment and the distance
        var closestLayer = this._calcClosestLayer(marker.getLatLng(), this._snapList);

        // find the final latlng that we want to snap to
        var snapLatLng = this._checkPrioritiySnapping(closestLayer);

        // minimal distance before marker snaps (in pixels)
        var minDistance = this.options.snapDistance;

        // event info for pm:snap and pm:unsnap
        var eventInfo = {
            marker: marker,
            snapLatLng: snapLatLng,
            segment: closestLayer.segment,
            layer: this._layer,
            layerInteractedWith: closestLayer.layer };

        if (closestLayer.distance < minDistance) {
            // snap the marker
            marker.setLatLng(snapLatLng);
            this._onMarkerDrag(e);

            // check if the snapping position differs from the last snap
            if (this._snapLatLng !== snapLatLng) {
                // if yes, save it and fire the pm:snap event
                this._snapLatLng = snapLatLng;
                marker.fire('pm:snap', eventInfo);
                this._layer.fire('pm:snap', eventInfo);
            }
        } else if (this._snapLatLng) {
            // no more snapping

            // if it was previously snapped...
            // ...unsnap
            this._unsnap(eventInfo);

            // and fire unsnap event
            eventInfo.marker.fire('pm:unsnap', eventInfo);
            this._layer.fire('pm:unsnap', eventInfo);
        }

        return true;
    },


    // we got the point we want to snap to (C), but we need to check if a coord of the polygon
    // receives priority over C as the snapping point. Let's check this here
    _checkPrioritiySnapping: function _checkPrioritiySnapping(closestLayer) {
        var map = this._layer._map;

        // A and B are the points of the closest segment to P (the marker position we want to snap)
        var A = closestLayer.segment[0];
        var B = closestLayer.segment[1];

        // C is the point we would snap to on the segment.
        // The closest point on the closest segment of the closest polygon to P. That's right.
        var C = closestLayer.latlng;

        // distances from A to C and B to C to check which one is closer to C
        var distanceAC = this._getDistance(map, A, C);
        var distanceBC = this._getDistance(map, B, C);

        // closest latlng of A and B to C
        var closestVertexLatLng = distanceAC < distanceBC ? A : B;

        // distance between closestVertexLatLng and C
        var shortestDistance = distanceAC < distanceBC ? distanceAC : distanceBC;

        // the distance that needs to be undercut to trigger priority
        var priorityDistance = this.options.snapDistance;

        // the latlng we ultemately want to snap to
        var snapLatlng = void 0;

        // if C is closer to the closestVertexLatLng (A or B) than the snapDistance,
        // the closestVertexLatLng has priority over C as the snapping point.
        if (shortestDistance < priorityDistance) {
            snapLatlng = closestVertexLatLng;
        } else {
            snapLatlng = closestLayer.latlng;
        }

        // return the snapping point
        return snapLatlng;
    },
    _createSnapList: function _createSnapList() {
        var _this2 = this;

        var layers = [];
        var debugIndicatorLines = [];

        // find all layers that are or inherit from Polylines...
        this._layer._map.eachLayer(function (layer) {
            if (layer instanceof L.Polyline) {
                layers.push(layer);

                // this is for debugging
                var debugLine = L.polyline([], { color: 'red' });
                debugIndicatorLines.push(debugLine);

                // uncomment 👇 this in to show debugging lines
                // debugLine.addTo(this._layer._map);
            }
        });

        // ...except myself
        layers = layers.filter(function (layer) {
            return _this2._layer !== layer;
        });

        this._snapList = layers;
        this.debugIndicatorLines = debugIndicatorLines;
    },
    _calcClosestLayer: function _calcClosestLayer(latlng, layers) {
        var _this3 = this;

        // the closest polygon to our dragged marker latlng
        var closestLayer = {};

        // loop through the layers
        layers.forEach(function (layer, index) {
            // find the closest latlng, segment and the distance of this layer to the dragged marker latlng
            var results = _this3._calcLayerDistances(latlng, layer);

            // show indicator lines, it's for debugging
            _this3.debugIndicatorLines[index].setLatLngs([latlng, results.latlng]);

            // save the info if it doesn't exist or if the distance is smaller than the previous one
            if (closestLayer.distance === undefined || results.distance < closestLayer.distance) {
                closestLayer = results;
                closestLayer.layer = layer;
            }
        });

        // return the closest layer and it's data
        // if there is no closest layer, return undefined
        return closestLayer;
    },
    _calcLayerDistances: function _calcLayerDistances(latlng, layer) {
        var _this4 = this;

        var map = this._layer._map;

        // is this a polyline, or polygon?
        var closedShape = layer instanceof L.Polygon;

        // the point P which we want to snap (probpably the marker that is dragged)
        var P = latlng;

        var coords = void 0;

        // the coords of the layer
        if (closedShape) {
            coords = layer.getLatLngs()[0];
        } else {
            coords = layer.getLatLngs();
        }

        // the closest segment (line between two points) of the layer
        var closestSegment = void 0;

        // the shortest distance from P to closestSegment
        var shortestDistance = void 0;

        // loop through the coords of the layer
        coords.forEach(function (coord, index) {
            // take this coord (A)...
            var A = coord;
            var nextIndex = void 0;

            // and the next coord (B) as points
            if (closedShape) {
                nextIndex = index + 1 === coords.length ? 0 : index + 1;
            } else {
                nextIndex = index + 1 === coords.length ? undefined : index + 1;
            }

            var B = coords[nextIndex];

            if (B) {
                // calc the distance between P and AB-segment
                var distance = _this4._getDistanceToSegment(map, P, A, B);

                // is the distance shorter than the previous one? Save it and the segment
                if (shortestDistance === undefined || distance < shortestDistance) {
                    shortestDistance = distance;
                    closestSegment = [A, B];
                }
            }

            return true;
        });

        // now, take the closest segment (closestSegment) and calc the closest point to P on it.
        var C = this._getClosestPointOnSegment(map, latlng, closestSegment[0], closestSegment[1]);

        // return the latlng of that sucker
        return {
            latlng: C,
            segment: closestSegment,
            distance: shortestDistance
        };
    },
    _getClosestPointOnSegment: function _getClosestPointOnSegment(map, latlng, latlngA, latlngB) {
        var maxzoom = map.getMaxZoom();
        if (maxzoom === Infinity) {
            maxzoom = map.getZoom();
        }
        var P = map.project(latlng, maxzoom);
        var A = map.project(latlngA, maxzoom);
        var B = map.project(latlngB, maxzoom);
        var closest = L.LineUtil.closestPointOnSegment(P, A, B);
        return map.unproject(closest, maxzoom);
    },
    _getDistanceToSegment: function _getDistanceToSegment(map, latlng, latlngA, latlngB) {
        var P = map.latLngToLayerPoint(latlng);
        var A = map.latLngToLayerPoint(latlngA);
        var B = map.latLngToLayerPoint(latlngB);
        return L.LineUtil.pointToSegmentDistance(P, A, B);
    },
    _getDistance: function _getDistance(map, latlngA, latlngB) {
        return map.latLngToLayerPoint(latlngA).distanceTo(map.latLngToLayerPoint(latlngB));
    }
};
"use strict";

/**
*
* A Leaflet Plugin For Editing Geometry Layers in Leaflet 1.0
* by Sumit Kumar (@TweetsOfSumit)
* Github Repo: https://github.com/codeofsumit/leaflet.pm
*/

L.PM = L.PM || {
    initialize: function initialize() {
        this.addInitHooks();
    },
    addInitHooks: function addInitHooks() {
        function initLayerGroup() {
            this.pm = new L.PM.Edit.LayerGroup(this);
        }

        L.LayerGroup.addInitHook(initLayerGroup);

        function initMarker() {
            this.pm = new L.PM.Edit.Marker(this);
        }

        L.Marker.addInitHook(initMarker);

        function initPolygon() {
            this.pm = new L.PM.Edit.Poly(this);
        }

        L.Polygon.addInitHook(initPolygon);

        function initPolyline() {
            this.pm = new L.PM.Edit.Line(this);
        }

        L.Polyline.addInitHook(initPolyline);

        function initMap() {
            this.pm = new L.PM.Map(this);
        }

        L.Map.addInitHook(initMap);
    }
};

// initialize leaflet.pm
L.PM.initialize();
'use strict';

L.PM.Map = L.Class.extend({
    initialize: function initialize(map) {
        this.map = map;
        this.Draw = new L.PM.Draw(map);
        this.Toolbar = new L.PM.Toolbar(map);
    },
    addControls: function addControls(options) {
        this.Toolbar.addControls(options);
    },
    enableDraw: function enableDraw() {
        var shape = arguments.length <= 0 || arguments[0] === undefined ? 'Poly' : arguments[0];
        var options = arguments[1];

        this.Draw.enable(shape, options);
    },
    disableDraw: function disableDraw() {
        var shape = arguments.length <= 0 || arguments[0] === undefined ? 'Poly' : arguments[0];

        this.Draw.disable(shape);
    },
    removeLayer: function removeLayer(e) {
        var layer = e.target;
        if (!layer._layers && !layer.pm.dragging()) {
            e.target.remove();
        }
    },
    toggleRemoval: function toggleRemoval(enabled) {
        var _this = this;

        if (enabled) {
            this.map.eachLayer(function (layer) {
                layer.on('click', _this.removeLayer);
            });
        } else {
            this.map.eachLayer(function (layer) {
                layer.off('click', _this.removeLayer);
            });
        }
    },
    globalEditEnabled: function globalEditEnabled() {
        return this._globalEditMode;
    },
    toggleGlobalEditMode: function toggleGlobalEditMode(options) {
        // find all layers that are or inherit from Polylines...
        var layers = [];
        this.map.eachLayer(function (layer) {
            if (layer instanceof L.Polyline || layer instanceof L.Marker) {
                layers.push(layer);
            }
        });

        // filter out layers that don't have the leaflet.pm instance
        layers = layers.filter(function (layer) {
            return !!layer.pm;
        });

        if (this.globalEditEnabled()) {
            // disable

            this._globalEditMode = false;

            layers.forEach(function (layer) {
                layer.pm.disable();
            });
        } else {
            // enable

            this._globalEditMode = true;

            layers.forEach(function (layer) {
                layer.pm.enable(options);
            });
        }
    }
});
'use strict';

L.PM.Draw = L.Class.extend({
    options: {
        templineStyle: {
            color: 'red'
        },
        hintlineStyle: {
            color: 'red',
            dashArray: [5, 5]
        }
    },
    initialize: function initialize(map) {
        var _this = this;

        // save the map
        this._map = map;

        // define all possible shapes that can be drawn
        this.shapes = ['Poly', 'Line', 'Marker'];

        // initiate drawing class for our shapes
        this.shapes.forEach(function (shape) {
            _this[shape] = new L.PM.Draw[shape](_this._map);
        });
    },
    getShapes: function getShapes() {
        // if somebody wants to know what shapes are available
        return this.shapes;
    },
    enable: function enable(shape, options) {
        if (!shape) {
            throw new Error('Error: Please pass a shape as a parameter. Possible shapes are: ' + this.getShapes().join(','));
        }

        // disable drawing for all shapes
        this.disable();

        // enable draw for a shape
        this[shape].enable(options);
    },
    disable: function disable() {
        var _this2 = this;

        // there can only be one drawing mode active at a time on a map
        // so it doesn't matter which one should be disabled.
        // just disable all of them
        this.shapes.forEach(function (shape) {
            _this2[shape].disable();
        });
    },
    addControls: function addControls() {
        var _this3 = this;

        // add control buttons for our shapes
        this.shapes.forEach(function (shape) {
            _this3[shape].addButton();
        });
    }
});
"use strict";

L.PM.Edit = L.Class.extend({
    includes: [DragMixin, SnapMixin]
});
'use strict';

L.PM.Draw.Line = L.PM.Draw.extend({
    initialize: function initialize(map) {
        this._map = map;
        this._shape = 'Line';
        this.toolbarButtonName = 'drawPolyline';
    },
    enable: function enable(options) {
        // TODO: Think about if these options could be passed globally for all
        // instances of L.PM.Draw. So a dev could set drawing style one time as some kind of config
        L.Util.setOptions(this, options);

        // enable draw mode
        this._enabled = true;

        // create a new layergroup
        this._layerGroup = new L.LayerGroup();
        this._layerGroup.addTo(this._map);

        // this is the polyLine that'll make up the polygon
        this._polyline = L.polyline([], this.options.templineStyle);
        this._layerGroup.addLayer(this._polyline);

        // this is the hintline from the mouse cursor to the last marker
        this._hintline = L.polyline([], this.options.hintlineStyle);
        this._layerGroup.addLayer(this._hintline);

        // change map cursor
        this._map._container.style.cursor = 'crosshair';

        // create a polygon-point on click
        this._map.on('click', this._createPolygonPoint, this);

        // sync the hintline on mousemove
        this._map.on('mousemove', this._syncHintLine, this);

        // fire drawstart event
        this._map.fire('pm:drawstart', { shape: this._shape });

        // toggle the draw button of the Toolbar in case drawing mode got enabled without the button
        this._map.pm.Toolbar.toggleButton(this.toolbarButtonName, true);
    },
    disable: function disable() {
        // disable draw mode

        // cancel, if drawing mode isn't even enabled
        if (!this._enabled) {
            return;
        }

        this._enabled = false;

        // reset cursor
        this._map._container.style.cursor = 'default';

        // unbind listeners
        this._map.off('click', this._createPolygonPoint, this);
        this._map.off('mousemove', this._syncHintLine, this);

        // remove layer
        this._map.removeLayer(this._layerGroup);

        // fire drawend event
        this._map.fire('pm:drawend', { shape: this._shape });

        // toggle the draw button of the Toolbar in case drawing mode got disabled without the button
        this._map.pm.Toolbar.toggleButton(this.toolbarButtonName, false);
    },
    enabled: function enabled() {
        return this._enabled;
    },
    toggle: function toggle(options) {
        if (this.enabled()) {
            this.disable();
        } else {
            this.enable(options);
        }
    },
    _syncHintLine: function _syncHintLine(e) {
        var polyPoints = this._polyline.getLatLngs();

        if (polyPoints.length > 0) {
            var lastPolygonPoint = polyPoints[polyPoints.length - 1];
            this._hintline.setLatLngs([lastPolygonPoint, e.latlng]);
        }
    },
    _createPolygonPoint: function _createPolygonPoint(e) {
        // is this the first point?
        var first = this._polyline.getLatLngs().length === 0;

        this._polyline.addLatLng(e.latlng);
        this._createMarker(e.latlng, first);

        this._hintline.setLatLngs([e.latlng, e.latlng]);
    },
    _finishShape: function _finishShape() {
        // get coordinates, create the leaflet shape and add it to the map
        var coords = this._polyline.getLatLngs();
        var polylineLayer = L.polyline(coords).addTo(this._map);

        // disable drawing
        this.disable();

        // fire the pm:create event and pass shape and layer
        this._map.fire('pm:create', {
            shape: this._shape,
            layer: polylineLayer
        });
    },
    _createMarker: function _createMarker(latlng) {
        // create the new marker
        var marker = new L.Marker(latlng, {
            draggable: false,
            icon: L.divIcon({ className: 'marker-icon' })
        });

        // add it to the map
        this._layerGroup.addLayer(marker);

        // a click on any marker finishes this shape
        marker.on('click', this._finishShape, this);

        return marker;
    }
});
'use strict';

L.PM.Draw.Marker = L.PM.Draw.extend({
    initialize: function initialize(map) {
        this._map = map;
        this._shape = 'Marker';
        this.toolbarButtonName = 'drawMarker';
    },
    enable: function enable(options) {
        // TODO: Think about if these options could be passed globally for all
        // instances of L.PM.Draw. So a dev could set drawing style one time as some kind of config
        L.Util.setOptions(this, options);

        // change enabled state
        this._enabled = true;

        // create a marker on click on the map
        this._map.on('click', this._createMarker, this);

        // toggle the draw button of the Toolbar in case drawing mode got enabled without the button
        this._map.pm.Toolbar.toggleButton(this.toolbarButtonName, true);

        // enable edit mode for existing markers
        this._map.eachLayer(function (layer) {
            if (layer instanceof L.Marker) {
                layer.pm.enable();
            }
        });
    },
    disable: function disable() {
        // cancel, if drawing mode isn't even enabled
        if (!this._enabled) {
            return;
        }

        // undbind click event, don't create a marker on click anymore
        this._map.off('click', this._createMarker, this);

        // disable dragging and removing for all markers
        this._map.eachLayer(function (layer) {
            if (layer instanceof L.Marker) {
                layer.pm.disable();
            }
        });

        // change enabled state
        this._enabled = false;
    },
    enabled: function enabled() {
        return this._enabled;
    },
    toggle: function toggle(options) {
        if (this.enabled()) {
            this.disable();
        } else {
            this.enable(options);
        }
    },
    _createMarker: function _createMarker(e) {
        // save coords of click
        var latlng = e.latlng;

        if (!latlng) {
            return;
        }

        // create marker
        var marker = new L.Marker(latlng, {
            draggable: true
        });

        // add marker to the map
        marker.addTo(this._map);

        // enable editing for the marker
        marker.pm.enable();

        // fire the pm:create event and pass shape and marker
        this._map.fire('pm:create', {
            shape: this._shape,
            marker: marker
        });
    }
});
'use strict';

L.PM.Draw.Poly = L.PM.Draw.Line.extend({
    initialize: function initialize(map) {
        this._map = map;
        this._shape = 'Poly';
        this.toolbarButtonName = 'drawPolygon';
    },
    _finishShape: function _finishShape() {
        // get coordinates, create the leaflet shape and add it to the map
        var coords = this._polyline.getLatLngs();
        var polygonLayer = L.polygon(coords).addTo(this._map);

        // disable drawing
        this.disable();

        // fire the pm:create event and pass shape and layer
        this._map.fire('pm:create', {
            shape: this._shape,
            layer: polygonLayer
        });
    },
    _createMarker: function _createMarker(latlng, first) {
        // create the new marker
        var marker = new L.Marker(latlng, {
            draggable: false,
            icon: L.divIcon({ className: 'marker-icon' })
        });

        // add it to the map
        this._layerGroup.addLayer(marker);

        // if the first marker gets clicked again, finish this shape
        if (first) {
            marker.on('click', this._finishShape, this);
        }
    }
});
'use strict';

// LayerGroup doesn't inherit from L.PM.Edit because it's just calling L.PM.Edit.Poly
// (which inherits from L.PM.Edit) for each layer,
// so it's not really a parent class
L.PM.Edit.LayerGroup = L.Class.extend({
    initialize: function initialize(layerGroup) {
        var _this = this;

        this._layerGroup = layerGroup;
        this._layers = this.findLayers();

        // init all layers of the group
        this._layers.forEach(function (layer) {
            return _this._initLayer(layer);
        });

        // if a new layer is added to the group, reinitialize
        // This only works for FeatureGroups, not LayerGroups
        // https://github.com/Leaflet/Leaflet/issues/4861
        this._layerGroup.on('layeradd', function (e) {
            _this._layers = _this.findLayers();

            // init the newly added layer
            if (e.layer.pm) {
                _this._initLayer(e.layer);
            }

            // if editing was already enabled for this group, enable it again
            // so the new layers are enabled
            if (e.target.pm.enabled()) {
                _this.enable(_this.getOptions());
            }
        });
    },
    findLayers: function findLayers() {
        // get all layers of the layer group
        var layers = this._layerGroup.getLayers();

        // filter out layers that don't have leaflet.pm
        layers = layers.filter(function (layer) {
            return !!layer.pm;
        });

        // return them
        return layers;
    },
    _initLayer: function _initLayer(layer) {
        var _this2 = this;

        // available events
        var availableEvents = ['pm:edit', 'pm:remove', 'pm:dragstart', 'pm:drag', 'pm:dragend', 'pm:snap', 'pm:unsnap', 'pm:raiseMarkers', 'pm:markerdragend', 'pm:markerdragstart'];

        // listen to the events of the layers in this group
        availableEvents.forEach(function (event) {
            layer.on(event, _this2._fireEvent, _this2);
        });

        // add reference for the group to each layer inside said group
        layer.pm._layerGroup = this._layerGroup;
    },
    _fireEvent: function _fireEvent(e) {
        this._layerGroup.fireEvent(e.type, e);
    },
    toggleEdit: function toggleEdit(options) {
        this._options = options;
        this._layers.forEach(function (layer) {
            layer.pm.toggleEdit(options);
        });
    },
    enable: function enable(options) {
        this._options = options;
        this._layers.forEach(function (layer) {
            layer.pm.enable(options);
        });
    },
    disable: function disable() {
        this._layers.forEach(function (layer) {
            layer.pm.disable();
        });
    },
    enabled: function enabled() {
        var enabled = this._layers.find(function (layer) {
            return layer.pm.enabled();
        });
        return !!enabled;
    },
    dragging: function dragging() {
        var dragging = this._layers.find(function (layer) {
            return layer.pm.dragging();
        });
        return !!dragging;
    },
    getOptions: function getOptions() {
        return this._options;
    }
});
'use strict';

L.PM.Edit.Line = L.PM.Edit.extend({
    initialize: function initialize(layer) {
        this._layer = layer;
        this._enabled = false;
    },
    toggleEdit: function toggleEdit(options) {
        if (!this.enabled()) {
            this.enable(options);
        } else {
            this.disable();
        }
    },
    enable: function enable() {
        var _this = this;

        var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

        this.options = options;

        if (!this.enabled()) {
            // if it was already enabled, disable first
            // we don't block enabling again because new options might be passed
            this.disable();
        }

        // change state
        this._enabled = true;

        // init markers
        this._initMarkers();

        // if polygon gets removed from map, disable edit mode
        this._layer.on('remove', function (e) {
            _this.disable(e.target);
        });

        // preventOverlap needs the turf library. If it's not included, deactivate it again
        // if(window.turf === undefined && this.options.preventOverlap) {
        //     console.warn('TurfJS not found, preventOverlap is deactivated');
        //     this.options.preventOverlap = false;
        // }

        if (this.options.draggable) {
            this._initDraggableLayer();
        }

        // if(this.options.preventOverlap) {
        //
        //     // if the dragged polygon should be cutted when overlapping another polygon, go ahead
        //     this._layer.on('pm:drag', this._handleOverlap, this);
        //
        //     // set new coordinates, more details inside the function
        //     this._layer.on('pm:dragend', this._applyPossibleCoordsChanges, this);
        // }
    },
    enabled: function enabled() {
        return this._enabled;
    },
    disable: function disable() {
        var poly = arguments.length <= 0 || arguments[0] === undefined ? this._layer : arguments[0];

        // if it's not enabled, it doesn't need to be disabled
        if (!this.enabled()) {
            return false;
        }

        // prevent disabling if polygon is being dragged
        if (poly.pm._dragging) {
            return false;
        }
        poly.pm._enabled = false;
        poly.pm._markerGroup.clearLayers();

        // clean up draggable
        poly.off('mousedown');
        poly.off('mouseup');

        // remove draggable class
        var el = poly._path;
        L.DomUtil.removeClass(el, 'leaflet-pm-draggable');

        return true;
    },
    _initMarkers: function _initMarkers() {
        var map = this._layer._map;

        // cleanup old ones first
        if (this._markerGroup) {
            this._markerGroup.clearLayers();
        }

        // add markerGroup to map, markerGroup includes regular and middle markers
        this._markerGroup = new L.LayerGroup();
        map.addLayer(this._markerGroup);

        // create marker for each coordinate
        var coords = this._layer._latlngs;

        // the marker array, it includes only the markers that're associated with the coordinates
        this._markers = coords.map(this._createMarker, this);

        // create small markers in the middle of the regular markers
        for (var k = 0; k < coords.length - 1; k += 1) {
            var nextIndex = k + 1;
            this._createMiddleMarker(this._markers[k], this._markers[nextIndex]);
        }

        if (this.options.snappable) {
            this._initSnappableMarkers();
        }
    },


    // creates initial markers for coordinates
    _createMarker: function _createMarker(latlng, index) {
        var marker = new L.Marker(latlng, {
            draggable: true,
            icon: L.divIcon({ className: 'marker-icon' })
        });

        marker._origLatLng = latlng;
        marker._index = index;

        marker.on('dragstart', this._onMarkerDragStart, this);
        marker.on('drag', this._onMarkerDrag, this);
        marker.on('dragend', this._onMarkerDragEnd, this);
        marker.on('contextmenu', this._removeMarker, this);

        this._markerGroup.addLayer(marker);

        return marker;
    },


    // creates the middle markes between coordinates
    _createMiddleMarker: function _createMiddleMarker(leftM, rightM) {
        var _this2 = this;

        var latlng = this._calcMiddleLatLng(leftM.getLatLng(), rightM.getLatLng());

        var middleMarker = this._createMarker(latlng);
        var middleIcon = L.divIcon({ className: 'marker-icon marker-icon-middle' });
        middleMarker.setIcon(middleIcon);

        // save reference to this middle markers on the neighboor regular markers
        leftM._middleMarkerNext = middleMarker;
        rightM._middleMarkerPrev = middleMarker;

        middleMarker.on('click', function () {
            // TODO: move the next two lines inside _addMarker() as soon as
            // https://github.com/Leaflet/Leaflet/issues/4484
            // is fixed
            var icon = L.divIcon({ className: 'marker-icon' });
            middleMarker.setIcon(icon);

            _this2._addMarker(middleMarker, leftM, rightM);
        });
        middleMarker.on('movestart', function () {
            // TODO: This is a workaround. Remove the moveend listener and
            // callback as soon as this is fixed:
            // https://github.com/Leaflet/Leaflet/issues/4484
            middleMarker.on('moveend', function () {
                var icon = L.divIcon({ className: 'marker-icon' });
                middleMarker.setIcon(icon);

                middleMarker.off('moveend');
            });

            _this2._addMarker(middleMarker, leftM, rightM);
        });
    },


    // adds a new marker from a middlemarker
    _addMarker: function _addMarker(newM, leftM, rightM) {
        // first, make this middlemarker a regular marker
        newM.off('movestart');
        newM.off('click');

        // now, create the polygon coordinate point for that marker
        var latlng = newM.getLatLng();
        var coords = this._layer._latlngs;
        var index = leftM._index + 1;

        coords.splice(index, 0, latlng);

        // associate polygon coordinate with marker coordinate
        newM._origLatLng = coords[index];

        // push into marker array & update the indexes for every marker
        this._markers.splice(index, 0, newM);
        this._markers.map(function (marker, i) {
            marker._index = i;
            return true;
        });

        // create the new middlemarkers
        this._createMiddleMarker(leftM, newM);
        this._createMiddleMarker(newM, rightM);

        // fire edit event
        this._fireEdit();

        if (this.options.snappable) {
            this._initSnappableMarkers();
        }
    },
    _removeMarker: function _removeMarker(e) {
        var marker = e.target;
        var coords = this._layer._latlngs;
        var index = marker._index;

        // only continue if this is NOT a middle marker (those can't be deleted)
        if (index === undefined) {
            return;
        }

        // remove polygon coordinate from this marker
        coords.splice(index, 1);

        // if the poly has no coordinates left, remove the layer
        // else, redraw it
        if (coords.length < 1) {
            this._layer.remove();
        } else {
            this._layer.redraw();
        }

        // remove the marker and the middlemarkers next to it from the map
        if (marker._middleMarkerPrev) {
            this._markerGroup.removeLayer(marker._middleMarkerPrev);
        }
        if (marker._middleMarkerNext) {
            this._markerGroup.removeLayer(marker._middleMarkerNext);
        }

        this._markerGroup.removeLayer(marker);

        // find neighbor marker-indexes
        var leftMarkerIndex = index - 1 < 0 ? undefined : index - 1;
        var rightMarkerIndex = index + 1 >= this._markers.length ? undefined : index + 1;

        // don't create middlemarkers if there is only one marker left
        // or if the middlemarker would be between the first and last coordinate of a polyline
        if (rightMarkerIndex && leftMarkerIndex && rightMarkerIndex !== leftMarkerIndex) {
            var leftM = this._markers[leftMarkerIndex];
            var rightM = this._markers[rightMarkerIndex];
            this._createMiddleMarker(leftM, rightM);
        }

        // remove the marker from the markers array & update indexes
        this._markers.splice(index, 1);
        this._markers.map(function (m, i) {
            m._index = i;
            return true;
        });

        // if the polygon should be cutted when overlapping another polygon, do it now
        // if(this.options.preventOverlap) {
        //     this._handleOverlap();
        //     this._applyPossibleCoordsChanges();
        // }

        // fire edit event
        this._fireEdit();
    },
    _onMarkerDrag: function _onMarkerDrag(e) {
        // dragged marker
        var marker = e.target;

        // the dragged markers neighbors
        var nextMarkerIndex = marker._index + 1 >= this._markers.length ? 0 : marker._index + 1;
        var prevMarkerIndex = marker._index - 1 < 0 ? this._markers.length - 1 : marker._index - 1;

        // update marker coordinates which will update polygon coordinates
        L.extend(marker._origLatLng, marker._latlng);
        this._layer.redraw();

        // update middle markers on the left and right
        // be aware that "next" and "prev" might be interchanged, depending on the geojson array
        var markerLatLng = marker.getLatLng();
        var prevMarkerLatLng = this._markers[prevMarkerIndex].getLatLng();
        var nextMarkerLatLng = this._markers[nextMarkerIndex].getLatLng();

        if (marker._middleMarkerNext) {
            var middleMarkerNextLatLng = this._calcMiddleLatLng(markerLatLng, nextMarkerLatLng);
            marker._middleMarkerNext.setLatLng(middleMarkerNextLatLng);
        }

        if (marker._middleMarkerPrev) {
            var middleMarkerPrevLatLng = this._calcMiddleLatLng(markerLatLng, prevMarkerLatLng);
            marker._middleMarkerPrev.setLatLng(middleMarkerPrevLatLng);
        }

        // if the dragged polygon should be cutted when overlapping another polygon, go ahead
        // if(this.options.preventOverlap) {
        //     this._handleOverlap();
        // }
    },
    _onMarkerDragEnd: function _onMarkerDragEnd(e) {
        // if(this.options.preventOverlap) {
        //     this._applyPossibleCoordsChanges();
        // }

        this._layer.fire('pm:markerdragend', {
            markerEvent: e
        });

        // fire edit event
        this._fireEdit();
    },
    _onMarkerDragStart: function _onMarkerDragStart(e) {
        this._layer.fire('pm:markerdragstart', {
            markerEvent: e
        });
    },
    _fireEdit: function _fireEdit() {
        // fire edit event
        this._layer.edited = true;
        this._layer.fire('pm:edit');
    },
    _calcMiddleLatLng: function _calcMiddleLatLng(latlng1, latlng2) {
        // calculate the middle coordinates between two markers
        // TODO: put this into a utils.js or something

        var map = this._layer._map;
        var p1 = map.project(latlng1);
        var p2 = map.project(latlng2);

        var latlng = map.unproject(p1._add(p2)._divideBy(2));

        return latlng;
    }
});
'use strict';

L.PM.Edit.Marker = L.PM.Edit.extend({
    initialize: function initialize(layer) {
        // layer is a marker in this case :-)
        this._marker = layer;
        this._enabled = false;

        // register dragend event e.g. to fire pm:edit
        this._marker.on('dragend', this._onDragEnd, this);
    },
    toggleEdit: function toggleEdit(options) {
        if (!this.enabled()) {
            this.enable(options);
        } else {
            this.disable();
        }
    },
    enable: function enable() {
        if (this.enabled()) {
            return;
        }
        this._enabled = true;

        // enable dragging and removal for the marker
        this._marker.dragging.enable();
        this._marker.on('contextmenu', this._removeMarker, this);
    },
    enabled: function enabled() {
        return this._enabled;
    },
    disable: function disable() {
        this._enabled = false;

        // disable dragging and removal for the marker
        this._marker.dragging.disable();
        this._marker.off('contextmenu', this._removeMarker, this);
    },
    _removeMarker: function _removeMarker(e) {
        var marker = e.target;
        marker.remove();
        marker.fire('pm:remove');
    },
    _onDragEnd: function _onDragEnd(e) {
        var marker = e.target;

        // fire the pm:edit event and pass shape and marker
        marker.fire('pm:edit');
    }
});
'use strict';

L.PM.Edit.Poly = L.PM.Edit.Line.extend({
    _initMarkers: function _initMarkers() {
        var map = this._layer._map;

        // cleanup old ones first
        if (this._markerGroup) {
            this._markerGroup.clearLayers();
        }

        // add markerGroup to map, markerGroup includes regular and middle markers
        this._markerGroup = new L.LayerGroup();
        map.addLayer(this._markerGroup);

        // create marker for each coordinate
        var coords = this._layer._latlngs[0];

        // the marker array, it includes only the markers that're associated with the coordinates
        this._markers = coords.map(this._createMarker, this);

        // create small markers in the middle of the regular markers
        for (var k = 0; k < coords.length; k += 1) {
            var nextIndex = k + 1 >= coords.length ? 0 : k + 1;
            this._createMiddleMarker(this._markers[k], this._markers[nextIndex]);
        }

        if (this.options.snappable) {
            this._initSnappableMarkers();
        }
    },


    // adds a new marker from a middlemarker
    _addMarker: function _addMarker(newM, leftM, rightM) {
        // first, make this middlemarker a regular marker
        newM.off('movestart');
        newM.off('click');

        // now, create the polygon coordinate point for that marker
        var latlng = newM.getLatLng();
        var coords = this._layer._latlngs[0];
        var index = leftM._index + 1;

        coords.splice(index, 0, latlng);

        // associate polygon coordinate with marker coordinate
        newM._origLatLng = coords[index];

        // push into marker array & update the indexes for every marker
        this._markers.splice(index, 0, newM);
        this._markers.map(function (marker, i) {
            marker._index = i;
            return true;
        });

        // create the new middlemarkers
        this._createMiddleMarker(leftM, newM);
        this._createMiddleMarker(newM, rightM);

        // fire edit event
        this._fireEdit();

        if (this.options.snappable) {
            this._initSnappableMarkers();
        }
    },
    _removeMarker: function _removeMarker(e) {
        var marker = e.target;
        var coords = this._layer._latlngs[0];
        var index = marker._index;

        // only continue if this is NOT a middle marker (those can't be deleted)
        if (index === undefined) {
            return;
        }

        // remove polygon coordinate from this marker
        coords.splice(index, 1);

        // if the poly has no coordinates left, remove the layer
        // else, redraw it
        if (coords.length < 1) {
            this._layer.remove();
        } else {
            this._layer.redraw();
        }

        // remove the marker and the middlemarkers next to it from the map
        this._markerGroup.removeLayer(marker._middleMarkerPrev);
        this._markerGroup.removeLayer(marker._middleMarkerNext);
        this._markerGroup.removeLayer(marker);

        // find neighbor marker-indexes
        var leftMarkerIndex = index - 1 < 0 ? this._markers.length - 1 : index - 1;
        var rightMarkerIndex = index + 1 >= this._markers.length ? 0 : index + 1;

        // don't create middlemarkers if there is only one marker left
        if (rightMarkerIndex !== leftMarkerIndex) {
            var leftM = this._markers[leftMarkerIndex];
            var rightM = this._markers[rightMarkerIndex];
            this._createMiddleMarker(leftM, rightM);
        }

        // remove the marker from the markers array & update indexes
        this._markers.splice(index, 1);
        this._markers.map(function (m, i) {
            m._index = i;
            return true;
        });

        // if the polygon should be cutted when overlapping another polygon, do it now
        // if(this.options.preventOverlap) {
        //     this._handleOverlap();
        //     this._applyPossibleCoordsChanges();
        // }

        // fire edit event
        this._fireEdit();
    }
});
'use strict';

L.Control.PMButton = L.Control.extend({
    options: {
        position: 'topleft'
    },
    // TODO: clean up variable names like _button should be _options and that domNodeVariable stuff
    initialize: function initialize(options) {
        this._button = L.Util.setOptions(this, options);
    },
    onAdd: function onAdd(map) {
        this._map = map;

        this._container = this._map.pm.Toolbar.container;
        this.buttonsDomNode = this._makeButton(this._button);
        this._container.appendChild(this.buttonsDomNode);

        return this._container;
    },
    onRemove: function onRemove() {},
    getText: function getText() {
        return this._button.text;
    },
    getIconUrl: function getIconUrl() {
        return this._button.iconUrl;
    },
    destroy: function destroy() {
        this._button = {};
        this._update();
    },
    toggle: function toggle(e) {
        if (typeof e === 'boolean') {
            this._button.toggleStatus = e;
        } else {
            this._button.toggleStatus = !this._button.toggleStatus;
        }
        this._applyStyleClasses();

        return this._button.toggleStatus;
    },
    toggled: function toggled() {
        return this._button.toggleStatus;
    },
    onCreate: function onCreate() {
        this.toggle(false);
    },
    _triggerClick: function _triggerClick(e) {
        this._button.onClick(e);
        this._clicked(e);
        this._button.afterClick(e);
    },
    _makeButton: function _makeButton(button) {
        var _this = this;

        var newButton = L.DomUtil.create('a', 'leaflet-buttons-control-button', this._container);
        if (button.toggleStatus) {
            L.DomUtil.addClass(newButton, 'active');
        }

        var image = L.DomUtil.create('div', 'control-icon', newButton);
        if (button.iconUrl) {
            image.setAttribute('src', button.iconUrl);
        }
        if (button.className) {
            L.DomUtil.addClass(image, button.className);
        }
        // before the actual click, trigger a click on currently toggled buttons to
        // untoggle them and their functionality
        L.DomEvent.addListener(newButton, 'click', function () {
            if (_this._button.disableOtherButtons) {
                _this._map.pm.Toolbar.triggerClickOnToggledButtons(_this);
            }
        });
        L.DomEvent.addListener(newButton, 'click', this._triggerClick, this);

        L.DomEvent.disableClickPropagation(newButton);
        return newButton;
    },
    _applyStyleClasses: function _applyStyleClasses() {
        if (!this._container) {
            return;
        }

        if (!this._button.toggleStatus) {
            L.DomUtil.removeClass(this.buttonsDomNode, 'active');
        } else {
            L.DomUtil.addClass(this.buttonsDomNode, 'active');
        }
    },
    _clicked: function _clicked() {
        if (this._button.doToggle) {
            this.toggle();
        }
        return;
    }
});
'use strict';

/**
* The Icons used in this Toolbar are CC-BY Glyphicons - http://glyphicons.com/
*/

L.PM.Toolbar = L.Class.extend({
    options: {
        drawMarker: true,
        drawPolygon: true,
        drawPolyline: true,
        editPolygon: true,
        dragPolygon: false,
        deleteLayer: true,
        position: 'topleft'
    },
    initialize: function initialize(map) {
        this.map = map;

        this.buttons = {};
        this.container = L.DomUtil.create('div', 'leaflet-pm-toolbar leaflet-bar leaflet-control');
        this._defineButtons();
    },
    getButtons: function getButtons() {
        return this.buttons;
    },
    addControls: function addControls() {
        var options = arguments.length <= 0 || arguments[0] === undefined ? this.options : arguments[0];

        // adds all buttons to the map specified inside options

        // first set the options
        L.Util.setOptions(this, options);

        // now show the specified buttons
        this._showHideButtons();
    },
    _addButton: function _addButton(name, button) {
        this.buttons[name] = button;
        this.options[name] = this.options[name] || false;

        return this.buttons[name];
    },
    triggerClickOnToggledButtons: function triggerClickOnToggledButtons(exceptThisButton) {
        // this function is used when - e.g. drawing mode is enabled and a possible
        // other active mode (like removal tool) is already active.
        // we can't have two active modes because of possible event conflicts
        // so, we trigger a click on all currently active (toggled) buttons
        for (var name in this.buttons) {
            if (this.buttons[name] !== exceptThisButton && this.buttons[name].toggled()) {
                this.buttons[name]._triggerClick();
            }
        }
    },
    toggleButton: function toggleButton(name, status) {
        // does not fire the events/functionality of the button
        // this just changes the state and is used if a functionality (like Draw)
        // is enabled manually via script

        // as some mode got enabled, we still have to trigger the click on the other buttons
        // to disable their mode
        this.triggerClickOnToggledButtons(this.buttons[name]);

        // now toggle the state of the button
        return this.buttons[name].toggle(status);
    },
    _defineButtons: function _defineButtons() {
        var _this = this;

        // some buttons are still in their respective classes, like L.PM.Draw.Poly
        var deleteButton = {
            className: 'icon-delete',
            onClick: function onClick() {},
            afterClick: function afterClick() {
                _this.map.pm.toggleRemoval(_this.buttons.deleteLayer.toggled());
            },
            doToggle: true,
            toggleStatus: false,
            disableOtherButtons: true,
            position: this.options.position
        };

        var drawPolyButton = {
            className: 'icon-polygon',
            onClick: function onClick() {},
            afterClick: function afterClick() {
                // toggle drawing mode
                _this.map.pm.Draw.Poly.toggle();
            },
            doToggle: true,
            toggleStatus: false,
            disableOtherButtons: true,
            position: this.options.position
        };

        var drawMarkerButton = {
            className: 'icon-marker',
            onClick: function onClick() {},
            afterClick: function afterClick() {
                // toggle drawing mode
                _this.map.pm.Draw.Marker.toggle();
            },
            doToggle: true,
            toggleStatus: false,
            disableOtherButtons: true,
            position: this.options.position
        };

        var drawLineButton = {
            className: 'icon-polyline',
            onClick: function onClick() {},
            afterClick: function afterClick() {
                // toggle drawing mode
                _this.map.pm.Draw.Line.toggle();
            },
            doToggle: true,
            toggleStatus: false,
            disableOtherButtons: true,
            position: this.options.position
        };

        var editButton = {
            className: 'icon-edit',
            onClick: function onClick() {},
            afterClick: function afterClick() {
                _this.map.pm.toggleGlobalEditMode({
                    snappable: true,
                    draggable: true
                });
            },
            doToggle: true,
            toggleStatus: false,
            disableOtherButtons: true,
            position: this.options.position
        };

        var dragButton = {
            className: 'icon-drag',
            onClick: function onClick() {},
            afterClick: function afterClick() {},
            doToggle: true,
            toggleStatus: false,
            disableOtherButtons: true,
            position: this.options.position
        };

        this._addButton('drawMarker', new L.Control.PMButton(drawMarkerButton));
        this._addButton('drawPolygon', new L.Control.PMButton(drawPolyButton));
        this._addButton('drawPolyline', new L.Control.PMButton(drawLineButton));
        this._addButton('editPolygon', new L.Control.PMButton(editButton));
        this._addButton('dragPolygon', new L.Control.PMButton(dragButton));
        this._addButton('deleteLayer', new L.Control.PMButton(deleteButton));
    },
    _showHideButtons: function _showHideButtons() {
        // loop through all buttons
        var buttons = this.getButtons();

        for (var btn in buttons) {
            if (this.options[btn]) {
                // if options say the button should be visible, add it to the map
                buttons[btn].setPosition(this.options.position);
                buttons[btn].addTo(this.map);
            } else {
                // if not, remove it
                buttons[btn].remove();
            }
        }
    }
});